// @ts-ignore
import * as Cookies from 'js-cookie'
import { ENV_IS_PRODUCTION } from '~/const/environment'

export const getCookie = (key: string) => Cookies.get(key)

export const saveCookie = (key: string, value: string) => {
    Cookies.set(key, value, {
        domain: ENV_IS_PRODUCTION ? '.thecharts.com' : window.location.hostname,
        expires: 375
    })
}
