











































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { countryList } from '~/const/country'
import { signup, signupProducer } from '~/api/auth'
import FormSocialButtons from '~/components/auth/FormSocialButtons.vue'
import { AuthStore, IOnLoginSuccess } from '~/store/auth'
import { prettifyApiError } from '~/services/prettify-api-error'
import { IS_ELITEPAGE } from '~/const/environment'
import { getCookie } from '~/utils/cookie'
import { IModalParams, ModalStore, ModalType } from '~/store/modal'

enum AccountType {
	Artist,
	Producer
}

@Component({ components: { FormSocialButtons } })
export default class SignupPage extends Vue {
	// consts
	AccountType = AccountType
	countryList = countryList
	ModalType = ModalType
	// data
	step = 1
	selectedAccountType: AccountType = null
	user: ISignupParams = {
		first_name: '',
		producer_name: '',
		email: '',
		password: ''
		// country: ''
	}
	isTermsOfServiceChecked = false
	signupError = ''

	@ModalStore.State('activeModal') activeModal: IModalParams
	@ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (IModalParams: IModalParams) => void
	@AuthStore.Action('onLoginSuccess') onLoginSuccess: (params: IOnLoginSuccess) => void

	get accountTypeIsArtist() {
		return this.selectedAccountType === AccountType.Artist
	}

	get accountTypeIsProducer() {
		return this.selectedAccountType === AccountType.Producer
	}

	goPreviousRouteOrHome() {
		this.SET_ACTIVE_MODAL({ modal: null })
		if (IS_ELITEPAGE) this.$router.back()
		// @ts-ignore
		else this.$router.push({ name: 'Home' })
	}

	chooseAccountType(accountType: AccountType) {
		this.selectedAccountType = accountType
	}

	async signup() {
		const referralCode = getCookie('producerReferralCode') ?? ''
		const signupParams = {
			...this.user,
			password1: this.user.password,
			password2: this.user.password,
			referral_code: referralCode
		}
		try {
			const { key } = this.accountTypeIsArtist ? await signup(signupParams) : await signupProducer(signupParams)
			this.onLoginSuccess({ key, doRedirect: true, redirectToProducer: this.accountTypeIsProducer })
		} catch (err) {
			this.signupError = prettifyApiError(err)
		}
	}

	validateFields() {
		this.signup()
	}

	created() {
		if (this.$route.query && this.$route.query.account_type === 'artist') this.selectedAccountType = AccountType.Artist
	}
}
